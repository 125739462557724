import * as React from "react"
import Emphasis from "../components/emphasis"
import H1 from "../components/h1"
import H2 from "../components/h2"
import H3 from "../components/h3"
import Layout from "../components/layout"
import H4 from "../components/h4"

export default function DesignSystemPage() {
  return (
    <Layout>
      <section className="container mx-auto bg-slate-200">
        <H1 className="text-center">Design System</H1>

        <section className="columns-3">
          <figure className="bg-primary w-32 h-32 rounded-full mx-auto"></figure>
          <figure className="bg-secondary w-32 h-32 rounded-full mx-auto"></figure>
          <figure className="bg-tertiary w-32 h-32 rounded-full mx-auto"></figure>
        </section>

        <section className="text-center pt-8">
          <H1>Heading Large</H1>
          <H2>Heading Medium</H2>
          <H3>Heading Small</H3>
          <Emphasis>Emphasis</Emphasis>
          <H4>H4</H4>
          <article>Body Content</article>
        </section>
      </section>
    </Layout>
  )
}
